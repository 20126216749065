import React from "react"
import {css} from "@emotion/core"
import AppStoreImage from "../../images/Login/appstore.png"
import BannerImage from "../../images/Login/banner.png"
import BannerSpImage from "../../images/Login/banner_sp.png"
import DownloadImage from "../../images/Login/download.png"
import GooglePlayImage from "../../images/Login/google-play.png"
import RegisterImage from "../../images/Login/register-image.png"

export const HowToContent1 = () => {
  const style = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;
    padding: 12px;
    color: var(--theme-color);
    line-height: 2;
    border: 1px solid var(--theme-color);
    
    
    & li {
      display: inline-block;
      list-style: none;
      margin-left: 8px;
      margin-right: 8px;
      
      @media(max-width: 640px) {
        display: block;
      }
    }
  `

  return (
    <div css={style} className="container bg-white text-center">
      <p>購入をするには</p>
      <ul>
        <li>①ケロロファンクラブへ会員登録</li>
        <li>②アカウント情報の登録が必要です。</li>
      </ul>
    </div>
  )
}

export const HowToContent2 = () => {
  const windowGlobal = typeof window !== "undefined" && window
  const clickHandler = () => windowGlobal.location.href = "gundamfanclub://"
  const ua = typeof windowGlobal.navigator !== "undefined" && windowGlobal.navigator.userAgent
  const isSp = typeof ua.indexOf !== "undefined" && (
      ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0
        && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0
  )

  const style = css`
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
    padding: 30px;
    background: var(--theme-color);
    color: white;
    line-height: 2;
    
    & img {
      max-width: 300px;
    }
    
    & p.note {
      margin-top: 16px;
      font-size:13px;
    }
    
    & p:nth-of-type(1) {
      font-weight: bold;
      
      @media(max-width: 640px) {
        text-align: center;
      }
    }
    
    & button {
      display: block;
      width: 90%;
      margin: 20px auto 8px;
      padding: 8px 16px;
      font-size:17px;
      background: #fff;
      border-radius: 30px;
      color: var(--theme-color);
    }
    
    @media(max-width: 640px) {
      flex-direction: column;
      
      & > div:nth-of-type(2) {
        margin-top: 20px;
        text-align: center;
      }
      
      & img {
        max-width: 260px;
      }
    }
  `

  return (
    <div css={style} className="container">
      <div>
        <p>ケロロファンクラブ会員の方で<br/>
          まだアカウント情報の登録をされていない方は<br className="when_pc"/>
          アプリより「アカウント情報の登録」をお願いします。</p>
        <p className="note">※ パスワードを忘れた方も<br/>
          アプリの「アカウント情報の登録・変更」画面より<br/>
          パスワードの再設定をお願いいたします。</p>
      </div>
      <div>
        <img src={RegisterImage} alt=""/>
      </div>
    </div>
  )
}
// registerImageの下のボタンをコメントアウト
//        <button onClick={clickHandler} style={isSp ? {} : {display: "none"}}>アプリに戻る</button>


export const HowToContent3 = () => {
  const style = css`
    margin-top: 12px;
    padding: 20px 30px;
    color: var(--theme-color);
    line-height: 2;
    border: 1px solid var(--theme-color);
    justify-content: space-between;
    align-items: center;
    
    @media(max-width: 640px) {
      padding: 20px;
    }
    
    & > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      
      & li {
        display: inline-block;
        list-style: none;
        margin-left: 8px;
        margin-right: 8px;
        
        @media(max-width: 640px) {
          display: block;
        }
      }
    }
    
    & > div:nth-of-type(2) {
      margin: 20px 0;
    }
    
    & > div:nth-of-type(3) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      & > div:nth-of-type(1) {
        max-width: 280px;
        
        @media(max-width: 640px) {
          display: none;
        }
      }
      
      & > div:nth-of-type(2) {
        display: flex;
        
        & a {
          margin-left: 8px;
        }
        
        & img {
          max-height: 64px;
        }
        
        @media(max-width: 640px) {
          justify-content: space-between;
          
          & a {
            width: 48%;
            margin-left: 0;
          }
          
          & img {
            max-height: none;
          }
        }
        
      }
    }
    
    & .when_pc {
      display: block;
      
      @media(max-width: 640px) {
        display: none;
      }
    }
    
    & .when_sp {
      display: none;
      
      @media(max-width: 640px) {
        display: block;
      }
    }
  `

  return (
    <div css={style} className="container bg-white">
      <div>
        <p>購入をするには</p>
        <ul>
          <li>①ケロロファンクラブへ会員登録</li>
          <li>②アカウント情報の登録が必要です。</li>
        </ul>
      </div>
      <div>
        <img src={BannerImage} alt="" className="when_pc"/>
        <img src={BannerSpImage} alt="" className="when_sp"/>
      </div>
      <div>
        <div>
          <img src={DownloadImage} alt=""/>
        </div>
        <div>
          <a href="https://itunes.apple.com/jp/app/id1668371511" target="_blank">
            <img src={AppStoreImage} alt="App Store"/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=jp.co.bandainamcomusiclive.KeroroFanClub" target="_blank">
            <img src={GooglePlayImage} alt="Google Play"/>
          </a>
        </div>
      </div>
    </div>
  )
}