import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Login from "../components/Login"
import {HowToContent1, HowToContent2, HowToContent3} from "../components/Login/howto"
import PageTitle from "../components/PageTitle";

export default ({location}) => (
  <Layout>
    <SEO title="ログイン"/>
    <section className="inner">
      <PageTitle>ログイン</PageTitle>
      <HowToContent1/>
      <Login location={location}/>
      <HowToContent2/>
      <HowToContent3/>
    </section>
  </Layout>
)